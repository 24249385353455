import React from 'react';
import Layout from '../components/Layout';
import Projects from './Projects';
import Scroll from '../components/Scroll';

import config from '../../config';
import { projectsData } from '../projectsData'

const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <h2 >{config.heading}</h2>
      </div>
      <Scroll type="id" element="one">
        <a href="#one" className="more">
          PROJECTS
          <p><i className="arrow down"></i></p>
        </a>
      </Scroll>
    </section>

    <section id="one" className="wrapper style1 special">
      <Projects projectsData={projectsData} />
    </section>
  </Layout>
);

export default IndexPage;
