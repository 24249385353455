module.exports = {
  siteTitle: 'Beatty Design Studio', // <title>
  manifestName: 'bdstudio',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#3c3c3c',
  manifestThemeColor: '#3c3c3c',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/images/website-icon.png',
  heading: 'BEATTY DESIGN STUDIO',
};
